<!--
  **********************************************
  此组件，运营后台和商家后台同时使用，开发时需要两边代码保持一致，复制一下
  **********************************************
 -->
<template>
  <div class="">
    <MessageHeader title="订单信息" />

    <div class="content">
      <el-row
        :gutter="20"
        class="content-row"
      >
        <el-col :span="24">
          <span>订单状态：{{ ORDER_STATUS_MAP[tradeOrderVo.orderStatus] }}</span>
          <span
            v-if="tradeOrderVo.autoCancelWaitConfirmTime > 0"
            class="red"
          >（请尽快接单，剩{{ handleTime }}订单将自动取消）</span>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="content-row"
      >
        <el-col :span="4">
          供应商名称：{{ tradeOrderVo.supplierName }}
        </el-col>
        <el-col :span="4">
          店铺名称：{{ tradeOrderVo.storeName }}
        </el-col>
        <el-col :span="4">
          订单总额：{{ tradeOrderVo.totalAmount }}元
        </el-col>
        <el-col :span="3">
          会员卡支付：{{ tradeOrderVo.membershipCardFee }}元
        </el-col>
        <el-col
          v-if="people === 'admin'"
          :span="3"
        >
          农行积分支付：{{ tradeOrderVo.scoreFee }}元
        </el-col>
        <el-col :span="3">
          买家实付：{{ tradeOrderVo.paymet }}元
        </el-col>
        <el-col :span="3">
          商家实收：{{ tradeOrderVo.supplierPayment }}元
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="content-row"
      >
        <el-col :span="4">
          创建时间：{{ dayjs(tradeOrderVo.createTime).format('YYYY-MM-DD HH:mm:ss') }}
        </el-col>
        <el-col
          v-if="tradeOrderVo.payName"
          :span="4"
        >
          支付方式：{{ tradeOrderVo.payName }}
        </el-col>
        <el-col
          v-if="tradeOrderVo.payTime"
          :span="4"
        >
          支付时间：{{ dayjs(tradeOrderVo.payTime).format('YYYY-MM-DD HH:mm:ss') }}
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="content-row"
      >
        <el-col :span="4">
          <p>平台券/商品券：{{ tradeOrderVo.couponFee }}元</p>
          <p v-if="tradeOrderVo.platCouponId">
            （ID:{{ tradeOrderVo.platCouponId }}）
          </p>
        </el-col>
        <el-col :span="4">
          <p>商家券/商品券：{{ tradeOrderVo.useExchangeCoupon === 1 ? '抵扣商品' : `${tradeOrderVo.discountFee}元` }}</p>
          <p v-if="tradeOrderVo.storeCouponId">
            （ID:{{ tradeOrderVo.storeCouponId }}）
          </p>
        </el-col>
        <el-col :span="4">
          活动优惠：{{ tradeOrderVo.promFee }}元
        </el-col>
        <el-col :span="3">
          运费：{{ tradeOrderVo.postFee }}元
        </el-col>
        <!-- 采摘卡票券商品 -->
        <el-col
          v-if="[9].includes(+tradeOrderVo.orderType)"
          :span="people === 'admin' && tradeOrderVo.unionName ? 3 : 9"
        >
          <div>权益卡：抵扣商品</div>
          <div>
            ({{ entitlementCard.exchangeCardName }}：
            <el-link
              v-if="people === 'admin'"
              type="primary"
              @click="toOrderDetail(entitlementCard.exchangeCardOrderId)"
            >
              {{ entitlementCard.exchangeCardOrderNo }}
            </el-link>
            <span v-else>{{ entitlementCard.exchangeCardOrderNo }}</span>
            ；权益：{{ entitlementCard.exchangeCardPowerName }}，抵扣{{ entitlementCard.exchangeCardPowerAppointNum }}次。)
          </div>
        </el-col>
        <el-col
          v-if="people === 'admin' && tradeOrderVo.unionName"
          :span="3"
        >
          工会名称：{{ tradeOrderVo.unionName }}
        </el-col>
        <el-col
          v-if="people === 'admin' && tradeOrderVo.batchName"
          :span="3"
        >
          福利券名称：{{ tradeOrderVo.batchName }}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup>
import {
  ref, watchEffect, computed,
} from 'vue';
import dayjs from 'dayjs';
import MessageHeader from './MessageHeader.vue';
import { ORDER_STATUS_MAP } from '../../normals';

const props = defineProps({
  orderDetail: {
    type: Object,
    default: () => {},
  },
  people: {
    type: String,
    default: '',
  },
});

const tradeOrderVo = ref({});
const entitlementCard = ref({});

const handleTime = computed(() => {
  let time = tradeOrderVo.value.autoCancelWaitConfirmTime;
  time = new Date(Number(time));
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  return `${minutes}分${seconds}秒`;
});

const toOrderDetail = (orderId) => {
  window.open(`${window.location.origin}/nb/order/detail?orderId=${orderId}`);
};

watchEffect(() => {
  tradeOrderVo.value = props.orderDetail?.tradeOrderVo || {};
  entitlementCard.value = props.orderDetail?.tradeOrderGoodsVoList[0] || {};
});

</script>

<style scoped lang="scss">
.content {
  font-size: 14px;
  line-height: 18px;
  .content-row {
    margin-bottom: 20px;
  }
}
</style>
