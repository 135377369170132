var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        _setup.MessageHeader,
        { attrs: { title: _setup.titleText } },
        [
          _setup.isDropShipping
            ? [
                +_setup.receiveStatus === 0
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small", plain: "" },
                        on: {
                          click: function ($event) {
                            return _setup.handleAuditAddress(
                              _setup.props.orderDetail?.tradeOrderVo
                            )
                          },
                        },
                      },
                      [_vm._v(" 改地址待处理 ")]
                    )
                  : _vm._e(),
                +_setup.receiveStatus === 1
                  ? _c("el-tag", { attrs: { type: "success" } }, [
                      _vm._v(" 改地址已同意 "),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _setup.goodsData,
            border: "",
            "header-cell-style": _setup.headerCellStyle,
          },
        },
        _vm._l(_setup.tableColumns, function (colum) {
          return _c("el-table-column", {
            key: colum.prop,
            attrs: { prop: colum.prop, label: colum.label, width: colum.width },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [_c("span", [_vm._v(_vm._s(row[colum.prop]))])]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }