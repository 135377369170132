var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.MessageHeader, { attrs: { title: "权益信息" } }),
      _c("div", { staticClass: "f16 margin-b10" }, [
        _c("span", [_vm._v("有效期：" + _vm._s(_setup.timeText))]),
      ]),
      _c(
        "el-table",
        {
          attrs: {
            data: _setup.goodsData,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
        },
        _vm._l(_setup.tableColumns, function (column) {
          return _c("el-table-column", {
            key: column.prop,
            attrs: { prop: column.prop, label: column.label },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      column.prop === "powerState"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_setup.powerStateList[row.powerState])
                            ),
                          ])
                        : _c("span", [_vm._v(_vm._s(row[column.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }