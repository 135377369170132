var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {},
    [
      _c(_setup.MessageHeader, { attrs: { title: "订单信息" } }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-row",
            { staticClass: "content-row", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [
                  _vm._v(
                    "订单状态：" +
                      _vm._s(
                        _setup.ORDER_STATUS_MAP[_setup.tradeOrderVo.orderStatus]
                      )
                  ),
                ]),
                _setup.tradeOrderVo.autoCancelWaitConfirmTime > 0
                  ? _c("span", { staticClass: "red" }, [
                      _vm._v(
                        "（请尽快接单，剩" +
                          _vm._s(_setup.handleTime) +
                          "订单将自动取消）"
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "content-row", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(
                  " 供应商名称：" +
                    _vm._s(_setup.tradeOrderVo.supplierName) +
                    " "
                ),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(
                  " 店铺名称：" + _vm._s(_setup.tradeOrderVo.storeName) + " "
                ),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(
                  " 订单总额：" +
                    _vm._s(_setup.tradeOrderVo.totalAmount) +
                    "元 "
                ),
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v(
                  " 会员卡支付：" +
                    _vm._s(_setup.tradeOrderVo.membershipCardFee) +
                    "元 "
                ),
              ]),
              _vm.people === "admin"
                ? _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v(
                      " 农行积分支付：" +
                        _vm._s(_setup.tradeOrderVo.scoreFee) +
                        "元 "
                    ),
                  ])
                : _vm._e(),
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v(
                  " 买家实付：" + _vm._s(_setup.tradeOrderVo.paymet) + "元 "
                ),
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v(
                  " 商家实收：" +
                    _vm._s(_setup.tradeOrderVo.supplierPayment) +
                    "元 "
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "content-row", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(
                  " 创建时间：" +
                    _vm._s(
                      _setup
                        .dayjs(_setup.tradeOrderVo.createTime)
                        .format("YYYY-MM-DD HH:mm:ss")
                    ) +
                    " "
                ),
              ]),
              _setup.tradeOrderVo.payName
                ? _c("el-col", { attrs: { span: 4 } }, [
                    _vm._v(
                      " 支付方式：" + _vm._s(_setup.tradeOrderVo.payName) + " "
                    ),
                  ])
                : _vm._e(),
              _setup.tradeOrderVo.payTime
                ? _c("el-col", { attrs: { span: 4 } }, [
                    _vm._v(
                      " 支付时间：" +
                        _vm._s(
                          _setup
                            .dayjs(_setup.tradeOrderVo.payTime)
                            .format("YYYY-MM-DD HH:mm:ss")
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "content-row", attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("p", [
                  _vm._v(
                    "平台券/商品券：" +
                      _vm._s(_setup.tradeOrderVo.couponFee) +
                      "元"
                  ),
                ]),
                _setup.tradeOrderVo.platCouponId
                  ? _c("p", [
                      _vm._v(
                        " （ID:" +
                          _vm._s(_setup.tradeOrderVo.platCouponId) +
                          "） "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("p", [
                  _vm._v(
                    "商家券/商品券：" +
                      _vm._s(
                        _setup.tradeOrderVo.useExchangeCoupon === 1
                          ? "抵扣商品"
                          : `${_setup.tradeOrderVo.discountFee}元`
                      )
                  ),
                ]),
                _setup.tradeOrderVo.storeCouponId
                  ? _c("p", [
                      _vm._v(
                        " （ID:" +
                          _vm._s(_setup.tradeOrderVo.storeCouponId) +
                          "） "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(
                  " 活动优惠：" + _vm._s(_setup.tradeOrderVo.promFee) + "元 "
                ),
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v(" 运费：" + _vm._s(_setup.tradeOrderVo.postFee) + "元 "),
              ]),
              [9].includes(+_setup.tradeOrderVo.orderType)
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        span:
                          _vm.people === "admin" &&
                          _setup.tradeOrderVo.unionName
                            ? 3
                            : 9,
                      },
                    },
                    [
                      _c("div", [_vm._v("权益卡：抵扣商品")]),
                      _c(
                        "div",
                        [
                          _vm._v(
                            " (" +
                              _vm._s(_setup.entitlementCard.exchangeCardName) +
                              "： "
                          ),
                          _vm.people === "admin"
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.toOrderDetail(
                                        _setup.entitlementCard
                                          .exchangeCardOrderId
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _setup.entitlementCard
                                          .exchangeCardOrderNo
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _setup.entitlementCard.exchangeCardOrderNo
                                  )
                                ),
                              ]),
                          _vm._v(
                            " ；权益：" +
                              _vm._s(
                                _setup.entitlementCard.exchangeCardPowerName
                              ) +
                              "，抵扣" +
                              _vm._s(
                                _setup.entitlementCard
                                  .exchangeCardPowerAppointNum
                              ) +
                              "次。) "
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.people === "admin" && _setup.tradeOrderVo.unionName
                ? _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v(
                      " 工会名称：" +
                        _vm._s(_setup.tradeOrderVo.unionName) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.people === "admin" && _setup.tradeOrderVo.batchName
                ? _c("el-col", { attrs: { span: 3 } }, [
                    _vm._v(
                      " 福利券名称：" +
                        _vm._s(_setup.tradeOrderVo.batchName) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }