<template>
  <div class="main">
    <!-- 订单信息 -->
    <DetailMessage :order-detail="orderDetail" />

    <p class="header">
      商品信息
    </p>
    <div class="detail-form">
      <span>订单编号：{{ tradeOrderVo.orderNo }}</span>
      <el-button
        type="text"
        size="mini"
        style="margin-left: 20px"
        @click="handleClipboard($event)"
      >
        复制
      </el-button>
      <template v-if="![8].includes(+tradeOrderVo.orderType)">
        <el-button
          v-if="tradeOrderVo.orderStatus === 2"
          type="primary"
          size="small"
          style="float: right;margin-bottom: 8px"
          @click="receiveOrder"
        >
          立即接单
        </el-button>
        <el-button
          v-if="tradeOrderVo.orderStatus === 2"
          size="small"
          style="float: right;margin-bottom: 8px"
          @click="refuseOrder"
        >
          拒绝接单
        </el-button>
        <el-button
          v-if="tradeOrderVo.orderStatus === 5"
          type="primary"
          size="small"
          style="float: right;margin-bottom: 8px"
          @click="goodsDelivery"
        >
          核 销
        </el-button>
        <el-button
          v-else-if="(tradeOrderVo.orderStatus === 4 || tradeOrderVo.orderStatus === 6)
            && +tradeOrderVo.supplyType !== 1"
          type="primary"
          size="small"
          style="float: right;margin-bottom: 8px"
          :disabled="tradeOrderVo.orderStatus !== 4 && (tradeOrderVo.orderStatus === 6 && !!tradeOrderVo.lastShippingTime) "
          @click="onDeliverGoods"
        >
          发 货
        </el-button>
      </template>
    </div>
    <el-table
      :data="goodsData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column
          v-if="tradeOrderVo.orderStatus === 4 || tradeOrderVo.orderStatus === 6"
          type="selection"
          width="55"
          :selectable="goodsSelectable"
        >
        </el-table-column> -->
      <el-table-column
        label="商品信息"
        min-width="200"
      >
        <template slot-scope="{row}">
          <div class="goods">
            <img :src="row.goodsImage.split(',')[0]" />
            <div>
              <p>{{ row.goodsName }}</p>
              <p class="id">
                ID：{{ row.goodsId }}
              </p>
              <p class="id">
                类型：{{ ORDER_TYPE[row.type] }}
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <template>
        <el-table-column
          v-if="[9].includes(+tradeOrderVo.orderType)"
          prop="shopName"
          label="门店"
        /><el-table-column
          v-else
          prop="belongSupplierName"
          label="供应商"
        />
      </template>
      <el-table-column
        label="规格"
      >
        <template slot-scope="{row}">
          <div v-if="[9].includes(+tradeOrderVo.orderType)">
            {{ row.appointDay }}
          </div>
          <div>{{ row.skuName }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="sellCount"
        label="数量"
      />
      <el-table-column
        prop="sellPrice"
        label="商品单价（元）"
      >
        <template #default="{row}">
          <div>
            {{
              [2, 3].includes(Number(row.type)) ? '' : row.sellPrice
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="sellTotal"
        label="商品总价（元）"
      />
      <el-table-column
        prop="discountFee"
        label="商家券优惠（元）"
      >
        <template #default="{row}">
          <div>
            {{
              tradeOrderVo.useExchangeCoupon === 1 ? '抵扣商品' : row.discountFee
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="活动优惠（元）"
      >
        <template slot-scope="{row}">
          {{ GOODS_TYPE_MAP[row.goodsType] }}活动优惠：{{ row.promFee }}
          <div v-if="row.fullMinusFee != 0">
            <div>满减活动：{{ row.fullMinusFee }}</div>
            <div>商家承担比例：{{ row.extraField.responsibilityRatio }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="membershipCardFee"
        label="会员卡支付"
      />
      <el-table-column label="商家实收（元）">
        <template slot-scope="{row}">
          <p>{{ row.supplierPayment }}</p>
          <p style="color: #736E6E;">
            含配送费：{{ row.postFee }}
          </p>
          <p style="color: #736E6E;">
            平台优惠：{{ row.couponFee }}
          </p>
          <p
            v-if="row.membershipCardFee > 0"
            style="color: #736E6E"
          >
            {{ tradeOrderVo.memberTypeExplain }}：{{ row.membershipCardFee }}
          </p>
        </template>
      </el-table-column>
      <!-- <el-table-column label="订单状态">
          <template slot-scope="{row}">
            <span>{{ GOODS_STATUS_MAP[row.subOrderStatus] || '无' }}</span>
          </template>
        </el-table-column> -->
      <el-table-column label="已发货数量/下单数量">
        <template slot-scope="{row}">
          <span>{{ Number(row.shipmentCount) }} / {{ Number(row.sellCount) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="售后状态">
        <template slot-scope="{row}">
          <span>{{ REFUND_STATUS_MAP[row.refundStatus] || '无' }}</span>
        </template>
      </el-table-column>
    </el-table>
    <p class="detail-form">
      买家留言：{{ tradeOrderVo.buyerMomo || '无' }}
    </p>

    <!-- 入住人信息 收件人信息 用户信息 -->
    <UserMessage :order-detail="orderDetail" />

    <!-- 农产品且普通快递 -->
    <template v-if="+tradeOrderVo.orderType=== 1 && +tradeOrderVo.shippingType === 1">
      <p
        class="header"
      >
        物流信息
      </p>
      <el-table
        :data="trackData"
        border
        :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
        :row-style="{'font-size': '14px', 'color': '#0D0202'}"
        :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
      >
        <el-table-column
          prop="packageNo"
          label="包裹编号"
        />
        <el-table-column
          prop="logisticNo"
          label="物流单号"
        />
        <el-table-column
          prop="logisticName"
          label="物流公司"
        />
        <el-table-column
          prop="createTime"
          label="发货时间"
        >
          <template slot-scope="{row}">
            <p>
              {{ dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="商品">
          <template slot-scope="{row}">
            <p
              v-for="item in row.packageGoodsDtoList"
              :key="item.id"
            >
              {{ item.goodsName }}
              <span>（x{{ item.shipmentCount }}）</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="物流状态">
          <template slot-scope="{row}">
            <el-button
              type="text"
              @click="handleTrack(row)"
            >
              {{ LOGISTIC_STATUS_MAP[row.logisticStatus] }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <!-- 用权益信息户信息[8权益卡] -->
    <EquitiesMessage
      v-if="[8].includes(+tradeOrderVo.orderType)"
      :order-detail="orderDetail"
    />

    <!-- 售后信息 -->
    <template>
      <p class="header">
        售后信息
      </p>
      <el-table
        :data="refundData"
        border
        :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
        :row-style="{'font-size': '14px', 'color': '#0D0202'}"
        :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
      >
        <el-table-column
          prop="refundNo"
          label="退款编号"
        />
        <el-table-column
          prop="applyRefundFee"
          label="申请退款金额（元）"
        />
        <el-table-column
          prop="refundFee"
          label="实际退款金额（元）"
        />
        <el-table-column label="申请时间">
          <template slot-scope="{row}">
            <p v-if="row.createTime">
              {{ dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="reasonName"
          label="退款原因"
        >
          <!-- <template slot-scope="{row}">
            <span>{{ REFUND_REASON_MAP[row.refundInfo.reasonName] }}</span>
          </template> -->
        </el-table-column>
        <el-table-column
          prop="reasonDescription"
          label="补充原因"
        />
        <el-table-column label="图片">
          <template slot-scope="{row}">
            <template v-if="row.imageUrlsList">
              <el-image
                v-for="img in row.imageUrlsList"
                :key="img"
                :src="img"
                fit="cover"
                :preview-src-list="row.imageUrlsList"
                style="width: 40px;height: 40px"
              />
            </template>
          </template>
        </el-table-column>
        <el-table-column label="售后状态">
          <template slot-scope="{row}">
            <el-button
              type="text"
              @click="handleRefundDetail(row)"
            >
              {{ REFUND_STATUS_MAP[row.refundStatus] }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <!-- 备注 -->
    <template>
      <div class="header">
        备注
        <span class="tip">订单备注仅平台客服和商家可见</span>
        <el-button
          v-show="!isTipsEdit"
          type="primary"
          size="medium"
          style="float: right"
          @click="isTipsEdit=true"
        >
          添加备注
        </el-button>
      </div>
      <el-table
        :data="remarkList"
        border
        :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
        :row-style="{'font-size': '14px', 'color': '#0D0202'}"
        :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
      >
        <el-table-column
          label="备注用户"
          prop="userName"
          width="120"
        />
        <el-table-column
          label="备注信息"
          prop="memo"
        />
        <el-table-column
          label="备注时间"
        >
          <template slot-scope="{row}">
            <p v-if="row.createTime">
              {{ dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') }}
            </p>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <!-- 添加备注 -->
    <el-dialog
      title="添加备注"
      :visible.sync="isTipsEdit"
    >
      <el-input
        v-model="tips"
        type="textarea"
        autosize
        style="margin-bottom: 10px"
        placeholder="请输入"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-show="isTipsEdit"
          v-loading="memoLoading"
          type="primary"
          size="medium"
          @click="handleRemark"
        >
          保存
        </el-button>
        <el-button
          v-show="isTipsEdit"
          size="medium"
          @click="isTipsEdit = false"
        >
          取消
        </el-button>
      </span>
    </el-dialog>

    <!-- 发货 -->
    <el-dialog
      v-loading="deliveryLoading"
      title="发货"
      :visible.sync="showDelivery"
      width="500px"
    >
      <el-form label-width="80px">
        <el-form-item label="物流公司">
          <el-select
            v-model="deliveryInfo.logisticName"
            filterable
          >
            <el-option
              v-for="item in logisticList"
              :key="item.logisticId"
              :label="item.logisticName"
              :value="item.logisticName"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="tradeOrderVo.shippingType !== 2"
          label="物流单号"
        >
          <el-input
            v-model="deliveryInfo.logisticNo"
            placeholder="请输入字母或数字或横杠组成的6-40位快递单号"
            maxlength="40"
            show-word-limit
            @input="deliveryInfo.logisticNo = deliveryInfo.logisticNo.replace(/[^a-zA-Z0-9-]/g, '')"
            @blur="deliveryInfo.logisticNo = deliveryInfo.logisticNo.replace(/^-*$/g, '')"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="handleDelivery"
        >确 定</el-button>
      </span>
    </el-dialog>

    <!-- 核销 -->
    <el-dialog
      v-loading="deliveryLoading"
      title="核销"
      :visible.sync="showVerify"
      width="30%"
      @close="handleCloseVerify"
    >
      <el-form
        label-width="80px"
        @submit.native.prevent
      >
        <el-form-item label="核销码">
          <el-input
            v-model="verifyCode"
            placeholder="请输入商品核销码"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleCloseVerify">取 消</el-button>
        <el-button
          type="primary"
          @click="handleVerify"
        >确 定</el-button>
      </span>
    </el-dialog>

    <!--- 核销提示 -->
    <el-dialog
      title="提示"
      :visible.sync="showVerifyTips"
      width="30%"
      @close="handleCloseVerifyTips"
    >
      {{ verifyTips }}
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleCloseVerifyTips">取 消</el-button>
        <el-button
          type="primary"
          @click="handleVerifyActionType"
        >{{ verifyActionButton }}</el-button>
      </span>
    </el-dialog>

    <!-- 订单发货弹窗 -->
    <DeliverOrderDialog
      v-model="showDeliverModel"
      :order-data="tradeOrderVo"
      type="add"
      @success="deliverSuccess"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DeliverOrderDialog from './delivery/components/deliver-order-dialog.vue';
import DetailMessage from './components/orderDetail/DetailMessage.vue';
import EquitiesMessage from './components/orderDetail/EquitiesMessage.vue';
import UserMessage from './components/orderDetail/UserMessage.vue';
import clipboard from '@/utils/clipboard';
import {
  REFUND_STATUS_MAP,
  LOGISTIC_STATUS_MAP,
  REFUND_REASON_MAP,
  GOODS_STATUS_MAP,
  SHIPPING_TYPE_MAP,
  GOODS_TYPE_MAP,
  ORDER_TYPE,
} from './normals';

export default {
  name: 'order-detail',
  components: {
    DeliverOrderDialog,
    DetailMessage,
    EquitiesMessage,
    UserMessage,
  },
  data() {
    return {
      dayjs,
      REFUND_STATUS_MAP,
      LOGISTIC_STATUS_MAP,
      REFUND_REASON_MAP,
      GOODS_STATUS_MAP,
      SHIPPING_TYPE_MAP,
      GOODS_TYPE_MAP,
      orderDetail: null,
      ORDER_TYPE,
      goodsData: [],
      refundData: [],
      tips: '',
      isTipsEdit: false,
      operateData: [],
      trackData: [],
      tradeOrderVo: {},
      remarkList: [],
      subOrderIdList: [],
      showDelivery: false,
      deliveryInfo: {
        logisticNo: '',
        logisticName: '',
      },
      logisticList: [],
      deliveryLoading: false,
      memoLoading: false,
      showVerify: false,
      verifyCode: '', // 核销码

      // 核销门店与订单所属门店不同时，是否继续核销
      verifyAnyway: false,
      showVerifyTips: false,
      verifyTips: '',
      verifyActionButton: '',
      verifyActionType: '',
      // 发货弹窗数据
      showDeliverModel: false,
      timer: null,
    };
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    deliveryDisable() {
      return this.subOrderIdList.length === 0;
    },
  },
  async mounted() {
    this.handleClearInterval();
    await this.getDetail();
    this.showCountdown();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    handleClearInterval() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    showCountdown() {
      // 待确认取消的民宿订单，进行倒计时
      const { autoCancelWaitConfirmTime } = this.tradeOrderVo;
      if (autoCancelWaitConfirmTime) {
        this.timer = setInterval(() => {
          const currentTime = dayjs().valueOf();
          const remainTime = this.tradeOrderVo.countdownEndTime - currentTime;
          if (remainTime) {
            this.$set(this.tradeOrderVo, 'autoCancelWaitConfirmTime', remainTime - 1000);
          }
          if (this.tradeOrderVo.autoCancelWaitConfirmTime < 1000) {
            clearInterval(this.timer);
            setTimeout(() => {
              this.$router.go(0);
            }, 2000);
          }
        }, 1000);
      }
    },

    async getDetail() {
      const res = await this.$axios.get(this.$api.order.orderDetail, {
        params: {
          orderId: this.orderId,
        },
      });
      this.orderDetail = res.data;
      this.tradeOrderVo = res.data.tradeOrderVo;
      if (this.tradeOrderVo.autoCancelWaitConfirmTime) {
        const minutes = +this.tradeOrderVo.autoCancelWaitConfirmTime / 1000 / 60;
        this.$set(this.tradeOrderVo, 'countdownEndTime', dayjs().add(minutes, 'minute').valueOf());
      }
      this.goodsData = res.data.tradeOrderGoodsVoList;
      this.trackData = res.data.tradeOrderPackageDtoList;
      this.remarkList = res.data.tradeOrderMemoDtoList;
      this.refundData = res.data.tradeRefundDtoList;
      this.getLogisticList();
    },
    handleClipboard(event) {
      clipboard(this.tradeOrderVo.orderNo, event);
    },
    handleRemark() {
      this.memoLoading = true;
      this.$axios.post(this.$api.order.remark, {
        orderId: this.orderId,
        memo: this.tips,
      }).then((res) => {
        this.memoLoading = false;
        this.getDetail();
        this.isTipsEdit = false;
      });
    },
    handleTrack({ logisticNo, logisticCode, logisticName }) {
      const routeData = this.$router.resolve({
        path: '/nb/order/refund/track',
        query: {
          logisticNo,
          logisticCode,
          logisticName,
          orderId: this.orderId,
        },
      });
      window.open(routeData.href, '_blank');
    },
    handleRefundDetail(row) {
      const routeData = this.$router.resolve({
        path: '/nb/order/refund/detail',
        query: {
          refundId: row.refundId,
        },
      });
      window.open(routeData.href, '_blank');
    },
    // goodsSelectable(row, index) {
    //   return row.subOrderStatus === 2
    // },
    goodsDelivery() {
      const orderTypeList = [2, 5, 6, 9];
      if (orderTypeList.includes(+this.tradeOrderVo.orderType)) { // 民宿、美食、玩乐订单核销
        this.showVerify = true;
      } else if (this.tradeOrderVo.shippingType === 3) { // 自提订单核销
        this.showVerify = true;
      } else { // 快递发货
        this.showDelivery = true;
      }
    },
    // 发货
    onDeliverGoods() {
      this.showDeliverModel = true;
    },
    // 发货成功
    deliverSuccess() {
      this.getDetail();
    },
    handleSelectionChange(val) {
      this.subOrderIdList = val.map((item) => item.subOrderId);
    },
    // 获取商家发货快递列表
    getLogisticList() {
      this.$axios.get(this.$api.commonNew.getLogisticList, {
        params: {
          type: this.tradeOrderVo.shippingType,
        },
      }).then((res) => {
        this.logisticList = res.data || [];
      });
    },
    handleClose() {
      this.showDelivery = false;
      this.deliveryInfo = {
        logisticNo: '',
        logisticName: '',
      };
    },
    handleDelivery() {
      if (!this.deliveryInfo.logisticNo && this.tradeOrderVo.shippingType !== 2) {
        this.$message.error('请填写物流单号');
        return null;
      }
      if (this.deliveryInfo.logisticNo.length < 6 && this.tradeOrderVo.shippingType !== 2) {
        this.$message.error('请输入正确物流单号');
        return null;
      }
      if (!this.deliveryInfo.logisticName) {
        this.$message.error('请填写物流公司');
        return null;
      }
      this.deliveryLoading = true;
      this.$axios.post(this.$api.delivery.splitDelivery, {
        logisticNo: this.deliveryInfo.logisticNo.replace(/\s*/g, ''),
        logisticName: this.deliveryInfo.logisticName,
        subOrderIdList: this.subOrderIdList,
        orderNo: this.tradeOrderVo.orderNo,
      }).then((res) => {
        this.deliveryLoading = false;
        if (res.code === 0) {
          this.$message.success('发货成功');
          this.handleClose();
          this.subOrderIdList = [];
          this.getDetail();
        }
      });
    },

    // 关闭核销关联门店提示弹窗
    handleCloseVerifyTips() {
      this.showVerifyTips = false;
      this.verifyAnyway = false;
      this.verifyTips = '';
      this.verifyActionButton = '';
      this.verifyActionType = '';
    },

    // 处理用户点击 关联门店提示弹窗 按钮
    handleVerifyActionType() {
      const actionType = this.verifyActionType;

      if (!actionType) {
        return;
      }

      // 马上关联
      if (actionType === 8601) {
        window.open('/nb/setting/account/info', '_blank');
        return;
      }

      // 继续核销
      if (actionType === 8602) {
        this.verifyAnyway = true;
        this.handleVerify();
      }
    },

    // 关闭核销填写核销码弹窗
    handleCloseVerify() {
      this.showVerify = false;
      this.verifyCode = '';
    },

    // 核销请求
    handleVerify() {
      console.log(this.verifyCode);

      this.$axios.post(this.$api.order.verify, {

        verifyAnyway: this.verifyAnyway,
        code: this.verifyCode,
        orderId: this.orderId,

      })
        .then((res) => {
          console.log(res);
          if (res.code === 0) {
            this.showVerify = false;
            this.handleCloseVerifyTips();
            this.handleCloseVerify();
            this.$message.success('核销成功');
            this.getDetail();
          }

          if (res.code === 1001) {
            this.$message.error('核销码错误');
          }
          return res;
        })
        .then((res) => {
          // 店铺未关联自提门店
          if (res.code === 8601) {
            this.showVerifyTips = true;
            this.verifyTips = res.msg;
            this.verifyActionButton = '马上关联';
            this.verifyActionType = res.code;
            return res;
          }

          // 店铺已关联自提门店，但是核销码不属于该门店
          if (res.code === 8602) {
            this.showVerifyTips = true;
            this.verifyTips = res.msg;
            this.verifyActionButton = '继续核销';
            this.verifyActionType = res.code;
            return res;
          }

          return res;
        });
    },
    receiveOrder() {
      this.$axios.post(this.$api.order.receiveOrder, [this.tradeOrderVo.orderId]).then((res) => {
        if (res.code === 0) {
          this.$message.success('接单成功');
          this.getDetail();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    refuseOrder() {
      this.$confirm('确认拒绝该订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.order.refuseOrder, [this.tradeOrderVo.orderId]).then((res) => {
          if (res.code === 0) {
            this.$message.success('已拒绝接单');
            this.getDetail();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  background: #fff;
  padding: 20px;
}
.header {
  border-left: 3px solid #409EFF;
  padding: 12px;
  font-size: 18px;
  margin: 20px 0;
  .tip {
    font-size: 12px;
    color: #999
  }
  .title {
    display: inline-block;
    margin-right: 50px;
  }
}
.detail-form {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  td {
    padding: 12px 16px;
  }
}
.order-tips {
  font-size: 14px;
  border: 1px solid #ebeef5;
  padding: 20px;
  text-align: center;
}
.goods{
  display: flex;
  img {
    width: 70px;
    height: 70px;
  }
  p {
    padding: 0 12px;
  }
  .id {
    color: #736E6E;
  }
}
.remark {
  padding: 12px 8px;
  border: 1px solid #dfe6ec;
  margin-bottom: 10px;
}
</style>
