var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("DetailMessage", { attrs: { "order-detail": _vm.orderDetail } }),
      _c("p", { staticClass: "header" }, [_vm._v(" 商品信息 ")]),
      _c(
        "div",
        { staticClass: "detail-form" },
        [
          _c("span", [_vm._v("订单编号：" + _vm._s(_vm.tradeOrderVo.orderNo))]),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "text", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.handleClipboard($event)
                },
              },
            },
            [_vm._v(" 复制 ")]
          ),
          ![8].includes(+_vm.tradeOrderVo.orderType)
            ? [
                _vm.tradeOrderVo.orderStatus === 2
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", "margin-bottom": "8px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.receiveOrder },
                      },
                      [_vm._v(" 立即接单 ")]
                    )
                  : _vm._e(),
                _vm.tradeOrderVo.orderStatus === 2
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", "margin-bottom": "8px" },
                        attrs: { size: "small" },
                        on: { click: _vm.refuseOrder },
                      },
                      [_vm._v(" 拒绝接单 ")]
                    )
                  : _vm._e(),
                _vm.tradeOrderVo.orderStatus === 5
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", "margin-bottom": "8px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.goodsDelivery },
                      },
                      [_vm._v(" 核 销 ")]
                    )
                  : (_vm.tradeOrderVo.orderStatus === 4 ||
                      _vm.tradeOrderVo.orderStatus === 6) &&
                    +_vm.tradeOrderVo.supplyType !== 1
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", "margin-bottom": "8px" },
                        attrs: {
                          type: "primary",
                          size: "small",
                          disabled:
                            _vm.tradeOrderVo.orderStatus !== 4 &&
                            _vm.tradeOrderVo.orderStatus === 6 &&
                            !!_vm.tradeOrderVo.lastShippingTime,
                        },
                        on: { click: _vm.onDeliverGoods },
                      },
                      [_vm._v(" 发 货 ")]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.goodsData,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { label: "商品信息", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { staticClass: "goods" }, [
                      _c("img", {
                        attrs: { src: row.goodsImage.split(",")[0] },
                      }),
                      _c("div", [
                        _c("p", [_vm._v(_vm._s(row.goodsName))]),
                        _c("p", { staticClass: "id" }, [
                          _vm._v(" ID：" + _vm._s(row.goodsId) + " "),
                        ]),
                        _c("p", { staticClass: "id" }, [
                          _vm._v(
                            " 类型：" + _vm._s(_vm.ORDER_TYPE[row.type]) + " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          [
            [9].includes(+_vm.tradeOrderVo.orderType)
              ? _c("el-table-column", {
                  attrs: { prop: "shopName", label: "门店" },
                })
              : _c("el-table-column", {
                  attrs: { prop: "belongSupplierName", label: "供应商" },
                }),
          ],
          _c("el-table-column", {
            attrs: { label: "规格" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    [9].includes(+_vm.tradeOrderVo.orderType)
                      ? _c("div", [_vm._v(" " + _vm._s(row.appointDay) + " ")])
                      : _vm._e(),
                    _c("div", [_vm._v(_vm._s(row.skuName))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "sellCount", label: "数量" },
          }),
          _c("el-table-column", {
            attrs: { prop: "sellPrice", label: "商品单价（元）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            [2, 3].includes(Number(row.type))
                              ? ""
                              : row.sellPrice
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "sellTotal", label: "商品总价（元）" },
          }),
          _c("el-table-column", {
            attrs: { prop: "discountFee", label: "商家券优惠（元）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tradeOrderVo.useExchangeCoupon === 1
                              ? "抵扣商品"
                              : row.discountFee
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "活动优惠（元）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.GOODS_TYPE_MAP[row.goodsType]) +
                        "活动优惠：" +
                        _vm._s(row.promFee) +
                        " "
                    ),
                    row.fullMinusFee != 0
                      ? _c("div", [
                          _c("div", [
                            _vm._v("满减活动：" + _vm._s(row.fullMinusFee)),
                          ]),
                          _c("div", [
                            _vm._v(
                              "商家承担比例：" +
                                _vm._s(row.extraField.responsibilityRatio) +
                                "%"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "membershipCardFee", label: "会员卡支付" },
          }),
          _c("el-table-column", {
            attrs: { label: "商家实收（元）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("p", [_vm._v(_vm._s(row.supplierPayment))]),
                    _c("p", { staticStyle: { color: "#736E6E" } }, [
                      _vm._v(" 含配送费：" + _vm._s(row.postFee) + " "),
                    ]),
                    _c("p", { staticStyle: { color: "#736E6E" } }, [
                      _vm._v(" 平台优惠：" + _vm._s(row.couponFee) + " "),
                    ]),
                    row.membershipCardFee > 0
                      ? _c("p", { staticStyle: { color: "#736E6E" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tradeOrderVo.memberTypeExplain) +
                              "：" +
                              _vm._s(row.membershipCardFee) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "已发货数量/下单数量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(Number(row.shipmentCount)) +
                          " / " +
                          _vm._s(Number(row.sellCount))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "售后状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.REFUND_STATUS_MAP[row.refundStatus] || "无")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("p", { staticClass: "detail-form" }, [
        _vm._v(
          " 买家留言：" + _vm._s(_vm.tradeOrderVo.buyerMomo || "无") + " "
        ),
      ]),
      _c("UserMessage", { attrs: { "order-detail": _vm.orderDetail } }),
      +_vm.tradeOrderVo.orderType === 1 && +_vm.tradeOrderVo.shippingType === 1
        ? [
            _c("p", { staticClass: "header" }, [_vm._v(" 物流信息 ")]),
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.trackData,
                  border: "",
                  "header-row-style": { "font-weight": 100, color: "#0D0202" },
                  "row-style": { "font-size": "14px", color: "#0D0202" },
                  "header-cell-style": {
                    "font-weight": "bold",
                    background: "#F0F2F8",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "packageNo", label: "包裹编号" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "logisticNo", label: "物流单号" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "logisticName", label: "物流公司" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "createTime", label: "发货时间" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .dayjs(row.createTime)
                                      .format("YYYY-MM-DD HH:mm:ss")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1757543521
                  ),
                }),
                _c("el-table-column", {
                  attrs: { label: "商品" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return _vm._l(
                            row.packageGoodsDtoList,
                            function (item) {
                              return _c("p", { key: item.id }, [
                                _vm._v(" " + _vm._s(item.goodsName) + " "),
                                _c("span", [
                                  _vm._v(
                                    "（x" + _vm._s(item.shipmentCount) + "）"
                                  ),
                                ]),
                              ])
                            }
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    258397810
                  ),
                }),
                _c("el-table-column", {
                  attrs: { label: "物流状态" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTrack(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.LOGISTIC_STATUS_MAP[
                                        row.logisticStatus
                                      ]
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    633371141
                  ),
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      [8].includes(+_vm.tradeOrderVo.orderType)
        ? _c("EquitiesMessage", { attrs: { "order-detail": _vm.orderDetail } })
        : _vm._e(),
      [
        _c("p", { staticClass: "header" }, [_vm._v(" 售后信息 ")]),
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.refundData,
              border: "",
              "header-row-style": { "font-weight": 100, color: "#0D0202" },
              "row-style": { "font-size": "14px", color: "#0D0202" },
              "header-cell-style": {
                "font-weight": "bold",
                background: "#F0F2F8",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "refundNo", label: "退款编号" },
            }),
            _c("el-table-column", {
              attrs: { prop: "applyRefundFee", label: "申请退款金额（元）" },
            }),
            _c("el-table-column", {
              attrs: { prop: "refundFee", label: "实际退款金额（元）" },
            }),
            _c("el-table-column", {
              attrs: { label: "申请时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.createTime
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .dayjs(row.createTime)
                                    .format("YYYY-MM-DD HH:mm:ss")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "reasonName", label: "退款原因" },
            }),
            _c("el-table-column", {
              attrs: { prop: "reasonDescription", label: "补充原因" },
            }),
            _c("el-table-column", {
              attrs: { label: "图片" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.imageUrlsList
                        ? _vm._l(row.imageUrlsList, function (img) {
                            return _c("el-image", {
                              key: img,
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: img,
                                fit: "cover",
                                "preview-src-list": row.imageUrlsList,
                              },
                            })
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "售后状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleRefundDetail(row)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.REFUND_STATUS_MAP[row.refundStatus]) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      [
        _c(
          "div",
          { staticClass: "header" },
          [
            _vm._v(" 备注 "),
            _c("span", { staticClass: "tip" }, [
              _vm._v("订单备注仅平台客服和商家可见"),
            ]),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isTipsEdit,
                    expression: "!isTipsEdit",
                  },
                ],
                staticStyle: { float: "right" },
                attrs: { type: "primary", size: "medium" },
                on: {
                  click: function ($event) {
                    _vm.isTipsEdit = true
                  },
                },
              },
              [_vm._v(" 添加备注 ")]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.remarkList,
              border: "",
              "header-row-style": { "font-weight": 100, color: "#0D0202" },
              "row-style": { "font-size": "14px", color: "#0D0202" },
              "header-cell-style": {
                "font-weight": "bold",
                background: "#F0F2F8",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "备注用户", prop: "userName", width: "120" },
            }),
            _c("el-table-column", {
              attrs: { label: "备注信息", prop: "memo" },
            }),
            _c("el-table-column", {
              attrs: { label: "备注时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.createTime
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .dayjs(row.createTime)
                                    .format("YYYY-MM-DD HH:mm:ss")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      _c(
        "el-dialog",
        {
          attrs: { title: "添加备注", visible: _vm.isTipsEdit },
          on: {
            "update:visible": function ($event) {
              _vm.isTipsEdit = $event
            },
          },
        },
        [
          _c("el-input", {
            staticStyle: { "margin-bottom": "10px" },
            attrs: { type: "textarea", autosize: "", placeholder: "请输入" },
            model: {
              value: _vm.tips,
              callback: function ($$v) {
                _vm.tips = $$v
              },
              expression: "tips",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTipsEdit,
                      expression: "isTipsEdit",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.memoLoading,
                      expression: "memoLoading",
                    },
                  ],
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.handleRemark },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTipsEdit,
                      expression: "isTipsEdit",
                    },
                  ],
                  attrs: { size: "medium" },
                  on: {
                    click: function ($event) {
                      _vm.isTipsEdit = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.deliveryLoading,
              expression: "deliveryLoading",
            },
          ],
          attrs: { title: "发货", visible: _vm.showDelivery, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showDelivery = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "物流公司" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.deliveryInfo.logisticName,
                        callback: function ($$v) {
                          _vm.$set(_vm.deliveryInfo, "logisticName", $$v)
                        },
                        expression: "deliveryInfo.logisticName",
                      },
                    },
                    _vm._l(_vm.logisticList, function (item) {
                      return _c("el-option", {
                        key: item.logisticId,
                        attrs: {
                          label: item.logisticName,
                          value: item.logisticName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.tradeOrderVo.shippingType !== 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "物流单号" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            "请输入字母或数字或横杠组成的6-40位快递单号",
                          maxlength: "40",
                          "show-word-limit": "",
                        },
                        on: {
                          input: function ($event) {
                            _vm.deliveryInfo.logisticNo =
                              _vm.deliveryInfo.logisticNo.replace(
                                /[^a-zA-Z0-9-]/g,
                                ""
                              )
                          },
                          blur: function ($event) {
                            _vm.deliveryInfo.logisticNo =
                              _vm.deliveryInfo.logisticNo.replace(/^-*$/g, "")
                          },
                        },
                        model: {
                          value: _vm.deliveryInfo.logisticNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.deliveryInfo, "logisticNo", $$v)
                          },
                          expression: "deliveryInfo.logisticNo",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleDelivery },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.deliveryLoading,
              expression: "deliveryLoading",
            },
          ],
          attrs: { title: "核销", visible: _vm.showVerify, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.showVerify = $event
            },
            close: _vm.handleCloseVerify,
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: { "label-width": "80px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "核销码" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品核销码" },
                    model: {
                      value: _vm.verifyCode,
                      callback: function ($$v) {
                        _vm.verifyCode = $$v
                      },
                      expression: "verifyCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseVerify } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleVerify } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.showVerifyTips, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.showVerifyTips = $event
            },
            close: _vm.handleCloseVerifyTips,
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.verifyTips) + " "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCloseVerifyTips } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleVerifyActionType },
                },
                [_vm._v(_vm._s(_vm.verifyActionButton))]
              ),
            ],
            1
          ),
        ]
      ),
      _c("DeliverOrderDialog", {
        attrs: { "order-data": _vm.tradeOrderVo, type: "add" },
        on: { success: _vm.deliverSuccess },
        model: {
          value: _vm.showDeliverModel,
          callback: function ($$v) {
            _vm.showDeliverModel = $$v
          },
          expression: "showDeliverModel",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }