<!--
  **********************************************
  此组件，运营后台和商家后台同时使用，开发时需要两边代码保持一致，复制一下
  **********************************************
 -->
<template>
  <div>
    <MessageHeader :title="titleText">
      <template v-if="isDropShipping">
        <el-button
          v-if="+receiveStatus === 0"
          type="primary"
          size="small"
          plain
          @click="handleAuditAddress(props.orderDetail?.tradeOrderVo)"
        >
          改地址待处理
        </el-button>
        <el-tag
          v-if="+receiveStatus === 1"
          type="success"
        >
          改地址已同意
        </el-tag>
      </template>
    </MessageHeader>
    <el-table
      :data="goodsData"
      border
      :header-cell-style="headerCellStyle"
    >
      <el-table-column
        v-for="colum of tableColumns"
        :key="colum.prop"
        :prop="colum.prop"
        :label="colum.label"
        :width="colum.width"
      >
        <template slot-scope="{row}">
          <span>{{ row[colum.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import {
  ref, watch, getCurrentInstance,
} from 'vue';
import { SHIPPING_TYPE_MAP } from '../../normals';
import MessageHeader from './MessageHeader.vue';

const app = getCurrentInstance().proxy;

const headerCellStyle = { 'font-weight': 'bold', background: '#F0F2F8', color: '#0D0202' };

const props = defineProps({
  orderDetail: {
    type: Object,
    default: () => {},
  },
});

const goodsData = ref([]);
const titleText = ref('');
const tableColumns = ref([]);
const isDropShipping = ref(false);
const receiveStatus = ref(); // 修改地址状态（-1：未申请，0：待审核，1：通过，2：拒绝）

// 监听更新数据
function updateData() {
  const tradeOrderVo = props.orderDetail.tradeOrderVo || {};
  const {
    receiverName, mobile, shippingType,
    province, city, district, town, address,
    orderType, orderStatus, modifyReceiveStatus,
  } = tradeOrderVo;
  const addressText = `${province || ''}${city || ''}${district || ''}${town || ''}${address || ''}`;
  const shippingText = SHIPPING_TYPE_MAP[shippingType];
  // 是否待发货
  isDropShipping.value = +orderStatus === 4;
  // 修改地址待审核
  receiveStatus.value = +modifyReceiveStatus;
  // 表格数据
  if ([2].includes(Number(orderType))) {
    titleText.value = '入住人信息';
    goodsData.value = [{ ...props.orderDetail.tradeOrderHotelVo, _identityType: '身份证' }];
    tableColumns.value = [
      { prop: 'identityName', label: '住客姓名' },
      { prop: 'mobile', label: '联系电话' },
      { prop: '_identityType', label: '证件类型' },
      { prop: 'identityNo', label: '证件号' },
    ];
  } else if ([3, 4, 5, 6, 8, 9].includes(Number(orderType))) {
    titleText.value = '联系人信息';
    goodsData.value = [{
      receiverName, mobile,
    }];
    tableColumns.value = [
      { prop: 'receiverName', label: '联系人姓名' },
      { prop: 'mobile', label: '联系电话' },
    ];
  } else {
    titleText.value = '收货人信息';
    goodsData.value = [{
      receiverName, mobile, shippingText, addressText,
    }];
    tableColumns.value = [
      { prop: 'receiverName', label: '收货人' },
      { prop: 'mobile', label: '联系方式' },
      { prop: 'shippingText', label: '配送方式' },
      { prop: 'addressText', label: '收货地址' },
    ];
  }
}

function handleAuditAddress(row) {
  app.$router.push({
    path: '/nb/order/address',
    query: {
      orderNo: row.orderNo,
    },
  });
}

watch(() => props.orderDetail, (row) => {
  if (!row) return;
  updateData();
}, {
  immediate: true,
});
</script>

<style lang="scss" scoped>
.detail-form {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  td {
    padding: 12px 16px;
  }
}
</style>
