<!--
  **********************************************
  此组件，运营后台和商家后台同时使用，开发时需要两边代码保持一致，复制一下
  **********************************************
 -->
<template>
  <div>
    <MessageHeader title="权益信息" />
    <div class="f16 margin-b10">
      <span>有效期：{{ timeText }}</span>
    </div>
    <el-table
      :data="goodsData"
      border
      :header-row-style="{'font-weight': 100, color: '#0D0202'}"
      :row-style="{'font-size': '14px', color: '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', background: '#F0F2F8'}"
    >
      <el-table-column
        v-for="column of tableColumns"
        :key="column.prop"
        :prop="column.prop"
        :label="column.label"
      >
        <template slot-scope="{row}">
          <span v-if="column.prop === 'powerState'">{{ powerStateList[row.powerState] }}</span>
          <span v-else>{{ row[column.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import dayjs from 'dayjs';
import MessageHeader from './MessageHeader.vue';

const powerStateList = {
  1: '待预约', 3: '已预约', 4: '已使用', 5: '已失效',
};
const tableColumns = [
  { prop: 'powerName', label: '权益名称' },
  { prop: 'powerState', label: '状态' },
  { prop: 'usedPower', label: '已核销次数' },
  { prop: 'appointPower', label: '已预约次数' },
];

const props = defineProps({
  orderDetail: {
    type: Object,
    default: () => {},
  },
});

const timeText = ref('');
const goodsData = ref([]);

watch(() => props.orderDetail, (row) => {
  console.log('watch', row);
  if (!row) return;
  updateData();
}, {
  immediate: true,
});

function updateData() {
  const userExchangeCard = props.orderDetail?.userExchangeCard ?? {};
  const { startTime, endTime, powerList = [] } = userExchangeCard;
  timeText.value = `${setTime(startTime)} 至 ${setTime(endTime)}`;
  goodsData.value = powerList;
}

function setTime(num) {
  return num ? dayjs(+num).format('YYYY-MM-DD HH:mm:ss') : '';
}
</script>

<style lang="scss" scoped>
.detail-form {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  td {
    padding: 12px 16px;
  }
}
</style>
